/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "./theme.scss";
@import "~ngx-toastr/toastr";
// @import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "/src/custom-calendar.scss";
// @import "~@carbon/charts/styles.css";
// @import "./plex-and-carbon-components.css";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto;
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*="flex-"] {
  padding-left: 10px;
  //padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}

.toast-success {
  background-color: mat.get-color-from-palette($my-app-accent);
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
}
.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  line-height: 14px;
  min-width: auto;
  color: mat.get-color-from-palette($my-app-primary);
}

.close.mat-button:hover {
  background-color: none !important;
}

.spacer1 {
  flex: 1 1 auto;
}

@media only screen and (max-width: 767px) {
  .display-flex {
    display: flex;
    flex-direction: column !important;
  }

  [class*="flex-"] {
    padding-left: unset !important;
  }
}

::ng-deep {
  .cal-month-view .cal-day-badge {
    background-color: black !important;
  }
}
