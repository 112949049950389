@use "@angular/material" as mat;

@include mat.core();

$blue: #4267b2;
$red: #dd4b39;

$md-sprinto-primary: (
  50: #e4e4e4,
  100: #bababa,
  200: #8d8d8d,
  300: #5f5f5f,
  400: #3c3c3c,
  500: #1a1a1a,
  600: #171717,
  700: #131313,
  800: #0f0f0f,
  900: #080808,
  A100: #ff5050,
  A200: #ff1d1d,
  A400: #e90000,
  A700: #cf0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-sprinto-secondary: (
  50: #fff0e0,
  100: #ffd9b3,
  200: #ffc080,
  300: #ffa64d,
  400: #ff9326,
  500: #ff8000,
  600: #ff7800,
  700: #ff6d00,
  800: #ff6300,
  900: #ff5000,
  A100: #ffffff,
  A200: #fff5f2,
  A400: #ffcfbf,
  A700: #ffbca6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-sprinto-warn: (
  50: #f6e0e4,
  100: #e7b3bc,
  200: #d88090,
  300: #c84d63,
  400: #bc2641,
  500: #b00020,
  600: #a9001c,
  700: #a00018,
  800: #970013,
  900: #87000b,
  A100: #ffb3b6,
  A200: #ff8085,
  A400: #ff4d53,
  A700: #ff343b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$my-app-primary: mat.define-palette($md-sprinto-primary);
$my-app-accent: mat.define-palette($md-sprinto-secondary);
$my-app-warn: mat.define-palette($md-sprinto-warn);
$my-app-theme: mat.define-light-theme(
  $my-app-primary,
  $my-app-accent,
  $my-app-warn
);

@include mat.all-component-themes($my-app-theme);

.bold-500 {
  font-weight: 500 !important;
}

.bold-600 {
  font-weight: 600 !important;
}

.bold-700 {
  font-weight: 700 !important;
}

.bold-800 {
  font-weight: 800 !important;
}

.bold-900 {
  font-weight: 900 !important;
}

.italic {
  font-style: italic !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.jcenter {
  justify-content: center !important;
}

.justify {
  text-align: justify !important;
}

.typo-color-accent {
  color: mat.get-color-from-palette($my-app-accent) !important;
}

.typo-color-accent-300 {
  color: mat.get-color-from-palette($my-app-accent, 300) !important;
}

.typo-color-surface {
  color: white;
}

.typo-color-primary-50 {
  color: mat.get-color-from-palette($my-app-primary, 50) !important;
}

.typo-color-primary-100 {
  color: mat.get-color-from-palette($my-app-primary, 100) !important;
}

.typo-color-primary-200 {
  color: mat.get-color-from-palette($my-app-primary, 200) !important;
}

.typo-color-primary-300 {
  color: mat.get-color-from-palette($my-app-primary, 300) !important;
}

.typo-color-primary-400 {
  color: mat.get-color-from-palette($my-app-primary, 400) !important;
}

.typo-color-accent-100 {
  color: mat.get-color-from-palette($my-app-accent, 100) !important;
}

.show-content {
  display: block !important;
}

.hide-content {
  display: none !important;
}

.w-10 {
  width: 10% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-10 {
  margin: 10px !important;
}

*[list="primary-400"] {
  background-color: mat.get-color-from-palette($my-app-primary, 400) !important;
}

*[list="accent"] {
  background-color: mat.get-color-from-palette($my-app-accent) !important;
}

*[button-color="blue"] {
  background-color: $blue !important;
}

*[button-color="red"] {
  background-color: $red !important;
}

*[button-color="accent"] {
  background-color: mat.get-color-from-palette($my-app-accent) !important;
}

*[button-color="warning"] {
  background-color: mat.get-color-from-palette($my-app-warn) !important;
}

*[button-color="white"] {
  color: white !important;
}

mat-icon[color="accent"] {
  path {
    fill: mat.get-color-from-palette($my-app-accent) !important;
  }
}
mat-icon[color="white"] {
  path {
    fill: white !important;
  }
}
mat-icon[color="warn"] {
  path {
    fill: mat.get-color-from-palette($my-app-warn) !important;
  }
}

//input field color customization

::ng-deep {
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-primary .mat-form-field-outline {
    color: mat.get-color-from-palette($my-app-accent);
  }

  .mat-raised-button.mat-accent {
    color: white !important;
  }
}

//input field size customization

::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
  height: 45px !important;
}

::ng-deep .mat-form-field-label-wrapper {
  top: -1.5em;
}

::ng-deep .mat-form-field-infix {
  padding: 0.4em 0px !important;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  .mat-error {
    formly-validation-message {
      font-size: 10px !important;
    }
  }
}

//formly stepper customization

// ::ng-deep .mat-horizontal-stepper-header-container{
//     display: none !important;
// }

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: transparent !important;
}

.mat-step-header .mat-step-icon {
  background-color: mat.get-color-from-palette($my-app-primary) !important;
}

//input field type number customization

//remove input number arrows
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

//workout list item customization

::ng-deep {
  app-workout .mat-list-item {
    width: 100% !important;
    font-size: 16px !important;
    border-bottom: 1px solid mat.get-color-from-palette($my-app-primary, 50);
  }
  app-workout .mat-list-item:hover {
    cursor: pointer !important;
  }

  app-schedule-workout .mat-list-item {
    width: 100% !important;
    font-size: 16px !important;
  }

  app-schedule-workout .mat-list-item:hover {
    cursor: move !important;
  }

  app-results .mat-list-item {
    width: 80% !important;
    font-size: 16px !important;
  }

  app-delete-plan .mat-list-item {
    font-size: 16px !important;
    border-bottom: 1px solid mat.get-color-from-palette($my-app-primary, 50);
  }
}

//mat-option autcomplete customization

::ng-deep {
  app-workout-add .mat-option,
  .mat-option-text {
    font-size: 16px !important;
  }
}

::ng-deep {
  // app-workout-phase-add .mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {
  //     width: 100%!important;
  // }

  // app-workout-phase-add .mat-list-base .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
  //     display: inline!important
  // }

  // app-workout-phase-add mat-list-option{
  //     cursor: unset!important;
  // }

  // app-workout-phase-add .mat-pseudo-checkbox,.mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
  //     color: white!important;
  //     border-color:white!important;
  //     background-color: transparent!important;
  // }

  app-workout-phase-add .mat-list-text {
    mat-icon[type="list-option"] {
      float: right !important;
      margin-top: 3px !important;
      cursor: move !important;
    }
  }
}

// $my-custom-level: mat.define-typography-level(
//     $font-family: 'Roboto,  "Helvetica Neue", sans-serif',
//     $font-weight: 400,
//   $font-size: 1rem,
//   letter
// );

$my-custom-typography: mat.define-typography-config(
  $font-family: 'Roboto,  "Helvetica Neue", sans-serif',
  $headline: mat.define-typography-level(48px, 32px, 400),
  $title: mat.define-typography-level(34px, 32px, 400),
  $subheading-2: mat.define-typography-level(24px, 28px, 400),
  $subheading-1: mat.define-typography-level(20px, 24px, 400),
  $body-2: mat.define-typography-level(16px, 24px, 400),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);

// @include mat.core($my-custom-typography);

@include mat.all-component-typographies($my-custom-typography);
